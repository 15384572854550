import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'

const CookiePolicy = styled.div`
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 2%;
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.25em;
  }
`

class CookiePolicyComponent extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.src = 'https://cdn.iubenda.com/iubenda.js'
    script.async = true

    document.body.appendChild(script)
  }

  render() {
    return (
      <div>
        <a
          href="https://www.iubenda.com/privacy-policy/39309978/cookie-policy"
          className="iubenda-white no-brand iubenda-embed iub-body-embed"
          title="Cookie Policy"
        >
          Cookie Policy
        </a>
      </div>
    )
  }
}

const CookiePolicyPage = () => (
  <Layout>
    <Seo
      title="Cookie Policy"
      description="Our Cookie Policy"
      path="cookie-policy"
    />
    <CookiePolicy>
      <div>
        <CookiePolicyComponent />
      </div>
    </CookiePolicy>
  </Layout>
)

export default CookiePolicyPage
